import logo from './logo.svg'
import slide1 from './slide1.png';
import slide2 from './slide2.png';
import slide3 from './slide3.png';
import slide4 from './slide4.png';
import slide5 from './slide5.png';
import slide6 from './slide6.png';
import slide7 from './slide7.png';
import slide8 from './slide8.png';
// import slide9 from './slide9.png';
import slide10 from './slide10.png';
const Images = {
    logo,
    slide1,
    slide10,
    slide2,
    slide3,
    slide4,
    slide5,
    slide6,
    slide7,
    slide8,
    // slide9
}

export default Images;