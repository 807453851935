import React from 'react'
import { Autoplay, Pagination, Navigation ,A11y } from 'swiper';
import { Swiper, SwiperSlide,  } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Slider from './Silder';
import Images from '../../Assets';
export default function Sliders() {
  return (
    <>
    <Swiper
    modules={[   Pagination, Navigation,A11y]}
    spaceBetween={20}
    slidesPerView={1}
    navigation
    // pagination={{ clickable: true }}
    // autoplay={{
    //     delay: 3000,
    //     disableOnInteraction: false,
    //   }}
    >
      <SwiperSlide><Slider title={'تطبيق شاطر'} src={Images.slide1} description={'تطبيق "شاطر" (المناهج الكويتية) هو تطبيق تعليمي لمذاكرة المنهج الدراسي العام بطريقة فريدة و مبتكرة يستخدم فيها إستراتيجيات (التعليم باللعب) '} /></SwiperSlide>
      <SwiperSlide><Slider title={'الشروح'} src={Images.slide2} description={'(شاهد شروح المدرسين للمواد الموجودة) عند اختيار الشروح تظهر مواد الكتب الدراسية للطالب على حسب مرحلته و عند اختيار مادة يظهر عدد المعلمين الذين شرحوا هذه المادة'} /></SwiperSlide>
      <SwiperSlide><Slider title={'الدروس و التمارين'} src={Images.slide3} description={'عند اختيار أحد المدرسين الذين شرحوا المادة المختارة، تظهر صفحات المادة اختر صفحة سيظهر شرح المدرس للصفحة مع تدريبات تفاعلية و متنوعة لمحتويات الصفحة المختارة'}/></SwiperSlide>
      <SwiperSlide><Slider title={'التمارين'} src={Images.slide10} description={'عند اختيار التمارين تظهر مواد الكتب الدراسية و عند اختيار مادة يظهر حقلين لتحديد أرقام الصفحات (من-الى) لتحديد صفحات معينة لمذاكرتها'}/></SwiperSlide>
      <SwiperSlide><Slider title={'النتائج'} src={Images.slide7} description={'عند اختيار النتائج تظهر نتائج التمرينات التي قمت بها مؤخرا حسب التاريخ و حسب المادة و يمكن مشاركة هذه النتائج مع الوالدين و المدرسين أو مع الأصدقاء '} /></SwiperSlide>
      <SwiperSlide><Slider title={'الأوائل'} src={Images.slide6} description={'عند اختيار الأوائل يظهر الطلبة المتصدرين و المدارس المتصدرة في نتائج التدريبات في في التطبيق '}/></SwiperSlide>
      <SwiperSlide><Slider title={'المعلم'} src={Images.slide8} description={'عند التسجيل بحساب معلم تظهر الفصول الموجودة على حسب المادة المسجل بها المعلم في التطبيق، و يمكن شرح مادة أي فصل صفحة صفحة '}/></SwiperSlide>
      <SwiperSlide><Slider title={'شرح المعلم'} src={Images.slide4} description={'عند شرح صفحة مختارة من أي فصل، يمكن اضافة تمارين تفاعلية متنوعة تخص الصفحة المشروحة، ويمكن ارسال الشرح الى الطلاب في نفس المدرسة، أو الى جميع المدارس'}/></SwiperSlide>
      <SwiperSlide><Slider  title={'سجل في التطبيق'} src={Images.slide5} description={'انضم لآلاف الطلبة و مئات المدارس المسجلين في تطبيق شاطر، و حقق أهداف أولادك بالتفوق من خلال التعليم التفاعلي الممتع و المخصص لمنهجهم الدراسي'}/></SwiperSlide>
      {/* <SwiperSlide><Slider /></SwiperSlide> */}
    </Swiper>
  </>
  )
}
