import { Button, Container } from "@mui/material";
import Header from "./components/Header";
import Sliders from "./components/Slider/Silders";
import './style.css'
import Icons from "./components/Icons";

function App() {
  return (
    <Container className="container">
      <Header />
      <Sliders />
      <Button style={{borderRadius:'25px',background:'#17b5da'}} className='btn' onClick={() => {

          window.open('https://apps.apple.com/eg/app/shattir-com/id1434200433?fbclid=IwAR3Mb-Qss8BsO-vfPwFgdkbhvdxt9un1YRZqL20_lbVpv_jfAxMqpqM3VVY');
      }} variant="contained">حمل التطبيق مجانا</Button>
    <Icons />
    </Container>
  );
}

export default App;
