import { Box, Typography } from '@mui/material'
import React from 'react'

export default function Slider({src, title, description}) {
  return (
    <Box pt={2} width='100%' display='flex' flexDirection='column' alignItems='center'>
        <Box className='slider-img'>
        <img style={{    height: '15rem',}} src={src} alt="" />
        </Box>
        <Typography className='slider-title' marginTop="1rem" align='center' variant="h5" fontSize="18px" component="h2">
{title}
        </Typography>
        <Typography className='slider-description' width='90%' mt='.5rem' align='center' variant="p" component="p" fontSize="12px">
         {description}
          </Typography>
    </Box>
  )
}
