import { Menu } from "@mui/icons-material";
import { Box, Container, Drawer } from "@mui/material";
import React, { useState } from "react";
import Images from "../Assets";
import { Link } from "react-router-dom";
export default function Header() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Container className="header-container">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Box>
          <img className="logo" src={Images.logo} alt="logo" />
        </Box>
        <Box>
          <Menu className="menu" onClick={handleOpen} color="#fff" />
        </Box>
        <Drawer
          style={{ marginTop: "15rem !important" }}
          anchor={"top"}
          open={open}
          onClose={handleClose}
        >
          <div className="menu-area" style={{ backgroundColor: "#17B5DA" }}>
            <nav className="navbar navbar-expand-lg">
              <div className="navbar-collapse">
                <div className="nav-inner">
                  <ul  className="nav main-menu menu navbar-nav ul-responsive">
                    <li>
                      <Link style={{color:'#fff'}} to="/">الرئيسية</Link>
                    </li>
                    <li>
                      <a style={{color:'#fff'}} href="/about-us">من نحن </a>
                    </li>
                    <li>
                      <a style={{color:'#fff'}} href="/privacy">سياسة الاستخدام </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </Drawer>
      </Box>
    </Container>
  );
}
