import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { FaTiktok, FaWhatsapp } from "react-icons/fa";
import {  AiFillTwitterSquare, AiOutlineTwitter } from "react-icons/ai";
import {BsInstagram} from 'react-icons/bs'
export default function Icons() {
  return (
    <Box className='icon-container' display='flex' flexDirection="column" alignItems="end"  mt=".5rem" pl='2rem' pr='2rem'>
      <Box>
        <Typography className='icon-title' variant="p" components="h3">
          تجدنا على
        </Typography>
      </Box>
      <Box display="flex" width="100%" justifyContent="space-between"  alignItems="center">
        <IconButton onClick={() => {

          window.open('https://api.whatsapp.com/send?phone=96566656645');
        }} style={{backgroundColor:'#17B5DA'}}>
          <FaWhatsapp className="icon"  />
        </IconButton>
        <IconButton onClick={() => {

          window.open('https://tiktok.com/@shattir_kw');
        }} style={{backgroundColor:'#17B5DA'}}>
          <FaTiktok className="icon"  />
        </IconButton>

        <IconButton onClick={() => {

          window.open('https://instagram.com/shattir_kw');
        }} style={{backgroundColor:'#17B5DA'}}>
          <BsInstagram className="icon"  />
        </IconButton>

        <IconButton onClick={() => {

          window.open('https://twitter.com/shattir_kw');
        }} style={{backgroundColor:'#17B5DA'}}>
          <AiOutlineTwitter className="icon" />
        </IconButton>
      </Box>
    </Box>
  );
}
